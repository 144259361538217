const containerOpenClass = 'open';
const containerHiddenClass = 'hidden';
const containerHiddenDelay = 500;
const containerNoAnimationClass = 'no-anim';
const containerLoadingClass = 'loading';
const imageContainerClass = 'image-container';
const nextClass = 'nextOnStack';
const prevClass = 'prevOnStack';

class ImageContainer {

    private container: HTMLElement;
    private readonly previewUrl: string;
    private readonly fullResUrl: string;

    private fullResRequested: boolean = false;

    private touchActive: boolean = false;
    private touchInitial: Touch;
    private touchLatest: Touch;
    private lightbox: Lightbox;

    constructor(previewUrl: string, fullResUrl: string, lightbox: Lightbox, active: boolean = false) {
        this.previewUrl = previewUrl;
        this.fullResUrl = fullResUrl;
        this.lightbox = lightbox;

        this.buildContainerElement(active);
        this.registerListeners();
    }

    public loadFullResImage() {
        if (this.fullResRequested)
            return;

        this.fullResRequested = true;
        this.setLoadingClass(true);

        const img = new Image();
        img.onload = () => {
            this.setLoadingClass(false);
            this.setBackgroundImageUrl(this.fullResUrl);
        };
        img.src = this.fullResUrl;
    }

    public setPrevClass(state) {
        this.container.classList.toggle(prevClass, state);
    }

    public setNextClass(state) {
        this.container.classList.toggle(nextClass, state);
    }

    public appendTo(parent: HTMLElement) {
        parent.appendChild(this.container);
    }

    public setOffset(offset: number) {
        this.container.classList.toggle(containerNoAnimationClass, offset !== 0);
        this.container.style.transform = offset !== 0 ? `translate(${offset}px)` : '';
    }

    private setLoadingClass(state: boolean) {
        this.container.classList.toggle(containerLoadingClass, state);
    }

    private setBackgroundImageUrl(url) {
        this.container.style.backgroundImage = `url('${url}')`;
    }

    private buildContainerElement(active: boolean = false) {
        this.container = document.createElement('div');
        this.container.classList.add(imageContainerClass);
        if (!active)
            this.setNextClass(true);
        this.setBackgroundImageUrl(this.previewUrl);
    }

    private registerListeners() {
        this.container.addEventListener('touchstart', (e: TouchEvent) => {
            this.touchActive = true;
            this.touchInitial = e.targetTouches[0];
            this.touchLatest = this.touchInitial;
            this.requestNextAnimationFrame();
        });

        this.container.addEventListener('touchmove', (e: TouchEvent) => {
            this.touchLatest = e.targetTouches[0];
        });

        this.container.addEventListener('touchend', (e: TouchEvent) => {
            let distance = this.touchLatest.screenX - this.touchInitial.screenX;
            const threshold = 20;

            this.setOffset(0);

            this.touchActive = false;
            this.touchInitial = null;
            this.touchLatest = null;

            if (Math.abs(distance) >= threshold) {
                if (distance < 0) {
                    this.lightbox.next();
                } else {
                    this.lightbox.prev();
                }
            }
        });

        this.container.addEventListener('touchcancel', (e: TouchEvent) => {
            this.touchActive = false;
            this.touchInitial = null;
            this.touchLatest = null;
            this.setOffset(0);
        });
    }

    private requestNextAnimationFrame() {
        if (this.touchActive) {
            requestAnimationFrame(() => this.handleAnimationFrame());
        }
    }

    private handleAnimationFrame() {
        if (!this.touchActive)
            return;

        let distance = this.touchLatest.screenX - this.touchInitial.screenX;
        this.setOffset(distance);

        this.requestNextAnimationFrame();
    }

}

export default class Lightbox {

    private readonly container: HTMLElement;
    private prevButton: HTMLButtonElement;
    private nextButton: HTMLButtonElement;
    private closeButton: HTMLButtonElement;
    private imageLinks: NodeList;

    private imageContainers: ImageContainer[] = [];

    private currentIndex: number = 0;
    private readonly maxIndex: number;

    private handleKeyUpReference: any;
    private containerHiddenTimeout: number;

    constructor(container: HTMLElement, imageLinks: NodeList) {
        this.container = container;
        this.prevButton = container.querySelector('.prev');
        this.nextButton = container.querySelector('.next');
        this.closeButton = container.querySelector('.close');
        this.imageLinks = imageLinks;

        this.maxIndex = imageLinks.length - 1;

        this.addEventListeners();
        this.createImageContainers();
    }

    public next() {
        if (this.currentIndex == this.maxIndex){
            this.goTo(0);
        }
        else {
            this.goTo(this.currentIndex + 1);
        }
    }

    public prev() {
        if (this.currentIndex == 0){
            this.goTo(this.maxIndex);
        }
        else{
            this.goTo(this.currentIndex - 1);
        }
    }

    public goTo(newIndex) {
        if (newIndex < 0 || newIndex > this.maxIndex)
            throw new Error('Index out of bounds');

        this.imageContainers.forEach((imageContainer, index) => {
            imageContainer.setPrevClass(index < newIndex);
            imageContainer.setNextClass(index > newIndex);
        });

        // load current full res image and preload next and previous
        this.preloadFullResImageFor(newIndex - 1);
        this.preloadFullResImageFor(newIndex);
        this.preloadFullResImageFor(newIndex + 1);

        this.currentIndex = newIndex;
    }

    public open() {
        //this.setContainerHiddenClass(false);
        this.container.classList.add(containerOpenClass);
        this.registerKeyboardListeners();
    }

    public close() {
        this.unregisterKeyboardListeners();
        this.container.classList.remove(containerOpenClass);
        //this.setContainerHiddenClass(true);
    }

    private addEventListeners() {
        this.prevButton.addEventListener('click', () => this.prev());
        this.nextButton.addEventListener('click', () => this.next());
        this.closeButton.addEventListener('click', () => this.close());
        this.imageLinks.forEach((link: HTMLLinkElement, index: number) => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                this.setNoAnimationClass(true);
                this.open();
                this.goTo(index);
                this.setNoAnimationClass(false, 10);
            });
        });
    }

    private createImageContainers() {
        this.imageLinks.forEach((link: HTMLLinkElement, index) => {
            let fullResUrl = link.href;
            let previewUrl = link.querySelector('img').src;
            let container = new ImageContainer(previewUrl, fullResUrl, this, index === 0);
            container.appendTo(this.container);
            this.imageContainers.push(container)
        });
    }

    private preloadFullResImageFor(index) {
        if (index < 0 || index > this.maxIndex)
            return;
        this.imageContainers[index].loadFullResImage();
    }

    private setNoAnimationClass(state: boolean, delay: number = null) {
        const setter = () => this.container.classList.toggle(containerNoAnimationClass, state);
        delay === null ? setter() : window.setTimeout(setter, delay);
    }

    private setContainerHiddenClass(state: boolean) {
        window.clearTimeout(this.containerHiddenTimeout);
        if (state)
            this.containerHiddenTimeout = window.setTimeout(() =>
                this.container.classList.add(containerHiddenClass), containerHiddenDelay);
        else
            this.container.classList.remove(containerHiddenClass)
    }

    private registerKeyboardListeners() {
        this.handleKeyUpReference = this.handleKeyUp.bind(this);
        document.addEventListener('keyup', this.handleKeyUpReference);
    }

    private unregisterKeyboardListeners() {
        document.removeEventListener('keyup', this.handleKeyUpReference);
        this.handleKeyUpReference = null;
    }

    private handleKeyUp(e) {
        switch (e.code) {
            case 'ArrowLeft':
                this.prev();
                break;
            case 'ArrowRight':
                this.next();
                break;
            case 'Escape':
                this.close();
                break;
        }
    }

}
