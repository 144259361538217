import Lightbox from "./lightbox";

const isHome = document.body.classList.contains('home');
const grid = document.querySelector('#Grid');

let lightboxContainer = document.querySelector<HTMLElement>('.lightbox');
let imageLinks = document.querySelectorAll<HTMLElement>('#Grid .item > a');
if (lightboxContainer) {
    const lightbox = new Lightbox(lightboxContainer, imageLinks);
}

const backToTopButton = document.querySelector('.back-to-top');

function fadeInGrid() {
    const gridImages = grid.querySelectorAll('.item img');

    gridImages.forEach((image: HTMLImageElement, index: number) => {
        image.classList.add('hide');
        if (!image.complete) {
            image.addEventListener('load', () => {
                image.classList.remove('hide');
            });
        } else {
            window.setTimeout(() => {
                image.classList.remove('hide');
            }, index * 10);
        }
    });
}

function fadeOutGrid(callback: () => void) {
    const items = grid.querySelectorAll('.item');
    const fadeOutLength = 500;
    const maxTimeout = items.length * 10;
    let timeout = maxTimeout;
    items.forEach((item: Element) => {
        setTimeout(() => item.classList.add('fadeOut'), timeout)
        timeout -= 10;
    });

    const contentItem = grid.querySelector('.content-item');
    if (contentItem) {
        setTimeout(() => contentItem.classList.add('fadeOut'), maxTimeout);
    }

    setTimeout(callback, maxTimeout + fadeOutLength);
}

function moveNavToTop() {
    const nav = document.querySelector('#Nav');
    nav.classList.add('top');
}

function moveNavToCenter() {
    const nav = document.querySelector('#Nav');
    nav.classList.add('down');
}

function registerLinks(links: NodeListOf<HTMLAnchorElement>) {
    links.forEach((link: HTMLAnchorElement) => link.addEventListener('click', (e) => {
        e.preventDefault();

        if (isHome && !link.classList.contains('logo')) {
            moveNavToTop();
        } else if (!isHome && link.classList.contains('logo')) {
            moveNavToCenter();
        }
        window.location.href = link.href;
        /*
        fadeOutGrid(() => {
        });*/
    }));
}

registerLinks(document.querySelectorAll('#Nav a'));

if (grid) {
    if (!lightboxContainer) {
        registerLinks(grid.querySelectorAll('.item > a'));
    }
    //fadeInGrid();
}

function goBackToTop() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}

let backToTopVisible = false;
if (backToTopButton) {
    backToTopButton.addEventListener('click', goBackToTop);
    document.addEventListener('scroll', () => {
        let y = window.scrollY;
        let visible = y > 5;
        if (visible != backToTopVisible) {
            backToTopButton.classList.toggle('hidden', !visible);
            backToTopVisible = visible;
        }
    });
}
